//
//
//
//
//
//
//

export default {
    name: "FormCaptcha",
    data() {
        return {
            newImage: null,
        };
    },
    methods: {
        getNewImage() {
            this.newImage = `${this.$config.pimcoreUrl}/captcha_image?${Date.now()}`;
        },
    },
    mounted() {
        this.newImage = `${this.$config.pimcoreUrl}/captcha_image?${Date.now()}`;
    },
}
