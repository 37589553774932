//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate';
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';

import FormControlSelect from './Controls/FormControlSelect';
import FormControlInput from './Controls/FormControlInput';
import FormControlCheckbox from './Controls/FormControlCheckbox';
import FormControlButton from './Controls/FormControlButton';
import FormContactLocations from './FormContactLocations.vue';
import FormControlRecaptcha from './Controls/FormControlRecaptcha';
import StageModal from '../Configurator/components/StageModal.vue';

export default {
    name: 'FormLoginContainer',
    props: ['data'],
    mixins: [validationMixin],
    components: {
        FormControlSelect,
        FormControlInput,
        FormControlCheckbox,
        FormControlButton,
        FormContactLocations,
        FormControlRecaptcha,
        StageModal,
    },
    data() {
        return {
            form: {
                email: null,
                password: null,
                passwordRepeat: null,
                resetEmail: null,
                captcha: null,
            },
            resetForm: {
                password: null,
                passwordRepeat: null,
            },
            sendResetPwError: false,
            isLoading: false,
            isLoggedIn: false,
            isLoginError: false,
            overlayActive: false,
            sendResetPw: false,
            resetPasswordPage: false,
            resetDone: false,
            userData: false,
        };
    },
    validations: {
        //add minlength for password
        form: {
            email: { required, email },
            captcha: { required },
            password: { required },
            resetEmail: { required, email },
        },
        resetForm: {
            password: { required, minLength: minLength(8) },
            passwordRepeat: { required, sameAs: sameAs('password') },
        },
    },
    async beforeMount() {
        this.userData = await this.$nuxt.context.app.api.User.userData();
    },
    async mounted() {
        if (this.$route.query.token) {
            this.resetPasswordPage = true;
        }

        const redirect = async () => {
            if (this.userData.isLoggedIn) {
                this.redirectToUserPage();
            }
        };

        const accountDeleted = localStorage?.getItem('account_deleted') ?? false;

        if (accountDeleted) {
            this.$dtoast.pop({
                icon: 'link link--icon link--copy',
                preset: 'success',
                heading: this.$t('login.after_account_deleted.heading'),
                content: this.$t('login.after_acount_deleted.text'),
                width: '100%',
                containerSideOffset: '25px',
                duration: '4000',
            });
            localStorage.removeItem('account_deleted');
            setTimeout(() => {
                redirect();
            }, 4000);
        } else {
            await redirect();
        }
    },
    methods: {
        redirectToUserPage() {
            window.location = this.$getLocalizedSlug(this.$store.state.siteProperties.user_page.id);
        },
        showCollectionToast(cb) {
            this.$dtoast.pop({
                icon: 'link link--icon link--copy',
                preset: 'success',
                heading: this.$t('login.after_add_to_collection.heading'),
                content: this.$t('login.after_add_to_collection.text'),
                width: '100%',
                containerSideOffset: '25px',
                duration: '4000',
            });

            setTimeout(cb, 3000);
        },
        async setNewPassword() {
            this.$v.$touch();
            if (this.$v.resetForm.password.$error || this.$v.resetForm.passwordRepeat.$error) return;
            this.$nuxt.context.app.api.User.setNewPassword({ token: this.$route.query.token, ...this.resetForm })
                .then(async () => {
                    this.$dtoast.pop({
                        preset: 'success',
                        heading: this.$t('form.login.new_password.headline'),
                        content: this.$t('form.login.new_password.text'),
                        width: '100%',
                        containerSideOffset: '25px',
                    });
                    this.resetDone = true;
                    this.sendResetPw = false;
                })
                .catch((error) => {
                    this.$dtoast.pop({
                        preset: 'success',
                        heading: this.$t('form.login.new_password.invalid.headline'),
                        content: this.$t('form.login.new_password.invalid.text'),
                        width: '100%',
                        containerSideOffset: '25px',
                    });
                });
        },
        async resetPassword() {
            this.$v.form.resetEmail.$touch();
            if (this.$v.form.resetEmail.$error) {
                return;
            }
            this.$nuxt.context.app.api.User.resetPassword(this.$v.form.resetEmail.$model, this.$v.form.captcha.$model)
                .then(async () => {
                    this.sendResetPw = true;
                })
                .catch((error) => {
                    this.sendResetPwError = true;
                });
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.form.email.$error || this.$v.form.password.$error) return;
            this.isLoginError = false;
            this.isLoading = true;
            this.$nuxt.context.app.api.User.login(this.form)
                .then(async (res) => {
                    window.localStorage.setItem('access_token', res.data.token);
                    const savedConfiguration = localStorage?.getItem('save_after_login') ?? false;
                    if (savedConfiguration) {
                        this.$nuxt.context.app.api.User.saveConfiguratorCode()
                            .then(() => {
                                this.showCollectionToast(this.redirectToUserPage);
                            })
                            .catch(() => {
                                this.showCollectionToast(this.redirectToUserPage);
                            });
                    } else {
                        this.redirectToUserPage();
                    }
                })
                .catch((error) => {
                    this.isLoginError = true;
                    console.log(error);
                });
            this.isLoading = false;
        },
    },
};
