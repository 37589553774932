//
//
//
//

export default {
    name: 'Loading',
    props: {
        size: {
            type: String,
            default: '2.5em',
        },
    }
};
