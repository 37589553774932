//
//
//
//
//
//
//
//
//
//
//

import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'FormControlRecaptcha',
    components: {
        VueRecaptcha,
    },
    props: {
        error: {
            type: String,
            default: null,
        },
        loadScript: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        recaptchaSiteKey() {
            return process.env.NUXT_ENV_RECAPTCHA_SITEKEY;
        },
    },
};
